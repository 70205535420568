.app { 
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.content-div{
    width: 90vw;
}

