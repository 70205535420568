.input-section{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.input-box{
    width: 70%;
}

.result{
    display: flex;
    justify-content: center;
}

.result h2{
    width: 70%;
    text-align: center;
    margin-top: 60px;
}